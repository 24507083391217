import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { UniversalPresenter } from "../presenters/UniversalPresenter";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { RequestRebuttalDex } from "@/data/payload/api/UniversalRequests";

export interface UniversalState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "incoming-shortland" })
class UniversalStore extends VuexModule implements UniversalState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";

  @Action
  validateRebuttalDex(payload: RequestRebuttalDex) {
    this.setLoading(true);
    const presenter = container.resolve(UniversalPresenter);
    return presenter
      .validateRebuttalDex(new RequestRebuttalDex(payload))
      .then((res: ResponsePayloadV2) => {
        this.setErrorCause("");
        this.setError(!res.success);
      })
      .catch((err: any) => {
        const response = err?.response?.data?.message?.en;
        const errSystem = err.response ? "server" : "internet";
        const notfound = response?.toLowerCase().includes("not found") || false;
        this.setErrorCause(notfound ? "notfound" : errSystem);
        this.setError(true);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }
  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }
}

export const UniversalController = getModule(UniversalStore);
