
import { RequestRebuttalDex } from "@/data/payload/api/UniversalRequests";
import { Vue } from "vue-class-component";
import { UniversalController } from "../../controllers/UniversalController";

export default class Sanggahan extends Vue {
  mounted() {
    UniversalController.validateRebuttalDex(
      new RequestRebuttalDex({
        rdUniqCode: this.params?.uniqCode,
        rdSttNo: this.params?.stt_no
      })
    );
  }

  get params(): any {
    return this.$route.query;
  }

  get isLoading() {
    return UniversalController.isLoading;
  }
  get isError() {
    return UniversalController.isError;
  }
  get errorCause() {
    return UniversalController.errorCause;
  }
}
