/* eslint-disable @typescript-eslint/camelcase */
export class RequestRebuttalDex {
  rdUniqCode = "";
  rdSttNo = "";

  constructor(fields?: Partial<RequestRebuttalDex>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      rd_uniq_code: this.rdUniqCode,
      rd_stt_no: this.rdSttNo
    });
  }
}
